import React from 'react'
import { Redirect, Route } from 'react-router-dom'

// conf
import { getCookie } from '~/services/functions'

const PrivateRoute = ({ component: Component, ...rest }) => {

  const tokenCookie = getCookie('@IBFEOnline:token')

  return (
    <Route
      {...rest}
      render={props =>

        tokenCookie
          ?
          <Component {...props} />
          :
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    />
  )
}

export default PrivateRoute