import { createGlobalStyle } from 'styled-components'
import { color, media, font } from './variables'

export default createGlobalStyle`
  .relative {
    position: relative;
    z-index: 1;
  }
  .static {
    position: static;
  }

  .m0 {
    margin: 0 !important;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mt30 {
    margin-top: 30px !important;
  }

  .mtb-50 {
    margin: 50px 0;
  }

  .center-mobile {
    ${media.max_xs} {
      text-align: center;
    }
  }

  .col-xs-0 {
    ${media.max_xs} {
      padding: 0;
      margin: 0;
      display: inline;
    }
  }

  .fs-0 {
    font-size: 0;
  }

  .col-in-block {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }

  .v-top {
    vertical-align: top;
  }

  .v-middle {
    vertical-align: middle;
  }

  .v-bottom {
    vertical-align: bottom;
  }

  .row-xs {
    margin-left: -15px;
    margin-right: -15px;

    ${media.sm} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .row-half {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .col-half {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .ul-menu {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
  }

  img.figure {
    margin-bottom: 30px;
  }

  .fw-300 {
    font-weight: 300 !important;
  }
  .fw-400 {
    font-weight: 400 !important;
  }
  .fw-600 {
    font-weight: 600 !important;
  }
  .fw-700 {
    font-weight: 700 !important;
  }
  .nowrap {
    white-space: nowrap;
  }
  .primaryFont {
    font-family: ${font.primary};
  }
  .secondFont {
    font-family: ${font.second};
  }

  .primaryColor {
    color: ${color.primary};
  }
  .secondColor {
    color: ${color.second};
  }
  .thirdColor {
    color: ${color.third};
  }

  ${media.sm} {
    .mobile-link {
      pointer-events: none;
      cursor: default;
    }
  }
`;