import React, { useState, useEffect } from 'react'
import api from '~/services/api'

// functions
import { createCookie, getCookie, deleteCookie } from '~/services/functions'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState('')
  const [signInState, setSigInState] = useState({ error: '', loading: false })

  // se já possuir sessão
  useEffect(() => {

    try { // checa se há usuário no cookie
      const userCookie = getCookie('@IBFEOnline:user')
      setUser(JSON.parse(userCookie))
    } catch (error) {
    }
  }, [])

  const signInUser = async (authParam) => {

    setSigInState({ ...signInState, loading: true })
    const res = await api.post('sessions', authParam)

    if (res.data.erro) {
      setSigInState({ error: res.data.erro, loading: false })
    }
    if (res.data.token) {
      setSigInState({ error: '', loading: false })
      api.defaults.headers.common['Authorization'] = `bearer ${res.data.token}`;

      // cria cookies para manter login entre plataformas
      createCookie('@IBFEOnline:token', res.data.token)
      createCookie('@IBFEOnline:user', JSON.stringify(res.data.usuario))

      setUser(res.data.usuario)
    }
  }

  const signOutUser = (link = false) => {
    api.defaults.headers.common['Authorization'] = ''

    // apaga cookies para deslogar
    deleteCookie('@IBFEOnline:token')
    deleteCookie('@IBFEOnline:user')

    if (link) window.location.href = link
    else window.location.reload()
  }

  return (
    <AuthContext.Provider value={{
      user,
      signIn: {
        signInUser,
        signInState
      },
      signOutUser
    }}>
      {children}
    </AuthContext.Provider>
  )
}