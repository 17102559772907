import styled from 'styled-components'
import { color, media, font } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const Container = styled.div`
  width: 100%;
  text-align: right;

  ${media.md}{
    text-align: left;
  }

  h2{
    &.title{
      color: ${color.primary};
      margin: 0 0 30px 0;
      text-align: left;
    }
  }
  
  .desc-right-sidebar{
    display: none;
  }
`

export const WrapVideo = styled.div`
  margin-bottom: 15px;

  ${media.md}{
    margin-bottom: 30px;
  }

  .wrap-infos-video{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    p.info-percentage{
      font-size: 10px;
      text-align: left;
      line-height: normal;
      color: ${color.third};
      margin: 0;
    }
    p.percentage-watched{
      font-size: 18px;
      font-weight: 700;
      margin-left: 30px;
      margin: 0;

      &.finished{
        color: ${color.primary}
      }
    }

  }
`
export const WrapTextDesc = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 30px;
  ${mixin.text};
  font-family: ${font.second};
  color: ${color.text};
  text-align: center;
  white-space: ${props => props.preLine ? 'pre-line' : 'normal'};

  ${media.sm} {
    text-align: left;
  }

  h4{
    &.sub-module{
      font-size: 20px;
      font-weight: 300;
      color: ${color.third};
    }
  }
  p{
    margin: 0 0 30px 0;
    color: ${color.second};

    &:last-child{
      margin: 0;
    }
  }
  a{
    font-weight: 700;
    
    &:hover{
      color: ${color.primary}
    }
  }
`
export const WrapButtonFinishedClass = styled.div`
  float: right;
  width: 130px;

  ${media.md}{
    float: right;
    margin-left: 15px;
  }

  p{
    font-size: 10px;
    text-align: center;
    color: ${color.second};
    margin-bottom: 15px;
    line-height: 16px;
    
    span{
      margin: 0 5px;
      font-weight: 700;
    }
  }
`
export const LabelFinishedCourse = styled.h3`
  padding: 10px;
  background-color: ${color.primary};
  color: #FFFFFF;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;

  span{
    margin: 0 5px;
  }
`