import React from 'react'

// style
import { Container, Close, WrapButtonFoot } from './style'

// image
import close from '~/assets/icons/close.svg'

const LightboxAlert = ({ title, msg, setLightboxAlert, closable = true, buttonsAlert }) => {

  return (
    <Container>
      {
        closable ?
          <div className="overlay-opacity" onClick={() => setLightboxAlert(false)}></div>
          :
          <div className="overlay-opacity"></div>
      }

      <div className="box">
        {
          closable &&
          <Close onClick={() => setLightboxAlert(false)}>
            <img src={close} alt="Fechar" />
          </Close>
        }
        <div className="content">
          {<div dangerouslySetInnerHTML={{ __html: title }} />}
          {<div dangerouslySetInnerHTML={{ __html: msg }} />}

          <WrapButtonFoot>
            {buttonsAlert && buttonsAlert}
          </WrapButtonFoot>
        </div>
      </div>

    </Container>
  )
}
export default LightboxAlert