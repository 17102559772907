import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

// conf
import { getCookie } from '~/services/functions'

// images
import logo from '~/assets/logo/ibfe-online-n.svg'
import leftArrow from '~/assets/icons/left-arrow.svg'

//style 
import { MainContainer, Content, Logo, BackButton } from './style'

// main style
import { color } from '~/styles/variables'

//rest
import { usePut } from '~/services/rest'

//conf
import { BASE_URL_SITE } from '~/services/conf'

// components
import FormRecoveryPassword from './Form/RecoveryPassword'

const RecoveryPassword = () => {

  const [resRecoveryPassword, sendRecoveryPassword] = usePut('usuarios/senha')

  const [email, setEmail] = useState('')

  const handleFormEmail = evt => {
    setEmail(evt.target.value)
  }

  const handleRecoveryPassword = () => {
    sendRecoveryPassword({ email })
  }

  const tokenCookie = getCookie('@IBFEOnline:token')

  if (tokenCookie) {
    return <Redirect to='/' />
  }

  return (
    <MainContainer>
      <Content bg={color.fourth}>
        <BackButton href={`${BASE_URL_SITE}`} title="Ir para o site">
          <img src={leftArrow} alt="Seta para trás" />
          Ir para o site
        </BackButton>
        <Logo src={logo} alt="IBFE Online" />
      </Content>
      <Content>
        <FormRecoveryPassword
          handleFormEmail={handleFormEmail}
          handleRecoveryPassword={handleRecoveryPassword}
          resRecoveryPassword={resRecoveryPassword}
          email={email} />
      </Content>
    </MainContainer>
  )
}

export default RecoveryPassword