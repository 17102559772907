import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'

// conf
import { BASE_URL_SITE } from "~/services/conf";

// components 
import Button from '~/components/form/Button'
import Timer from './Timer';

// styles
import { Container } from './style'
import { color } from '~/styles/variables'

const CursoItem = ({ ongoing, data }) => {

  const themeButton = {
    bg: color.primary,
    fc: '#FFFFFF',
    bdc: '#FFFFFF'
  }


  const valor_original = data.valor_original && data.valor_original !== 0 ? data.valor_original.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
  const valor = data.valor ? data.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Grátis';

  if (ongoing) {
    return (
      <Container ongoing>
        <Link to={`curso/${data.cursos.url}`} title={data.cursos.titulo}>
          <div className="wrap">
            <div className="wrap-info">
              <h2>{data.cursos.titulo}</h2>
              {data.iniciado_em &&
                <span className="date-start">Iniciado em&nbsp;<span className="text-captalize"> {format(new Date(data.iniciado_em), 'MMM/yy', { locale: pt })}</span></span>
              }
            </div>
          </div>
        </Link>
      </Container>
    )
  } else {
    return (
      <Container className={`${valor_original && data.inicio_promo && data.fim_promo && 'item-timer'}`}>
        <a href={`${BASE_URL_SITE}curso/${data.url}`} title={data.titulo} rel="noopener noreferrer">
          <div className="wrap">

            {
              data.tags &&
              <div className="wrap-tags">
                <span className="tag">{data.tags}</span>
              </div>
            }

            <div className="wrap-info">
              <h2>{data.titulo}</h2>
              {
                valor_original &&
                <span className="price price-off">
                  {valor_original}
                </span>
              }
              <span className="price">
                {valor}
              </span>
            </div>
            <div className="wrap-bt">
              <Button span theme={themeButton}>Conhecer</Button>
            </div>
          </div>

          {
            valor_original && data.inicio_promo && data.fim_promo &&
            <Timer fim_promo={data.fim_promo} />
          }
        </a>
      </Container>
    )
  }
}
export default CursoItem