import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { color, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

const Button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: ${props => props.width ? props.width : '140px'};
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 5px 15px;
  background: ${props => props.theme.bg};
  color: ${props => props.theme.fc};
  border: 1px solid ${props => props.theme.bdc};
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  ${mixin.transition('all 0.1s linear')};

  ${media.max_xs}{
    width: ${props => props.fullXs ? '100%' : props.width ? props.width : '140px'}
  }
  
  &.facebook-button{
    border-radius: 30px;
    background: #1877f2;
    border-color: #1877f2;
    color: #FFFFFF;

    svg{
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &:hover {
    opacity: 0.7;
    color: ${props => props.theme.bdc_hover ? props.theme.bdc_hover : "#FFFFFF"};
  }
  &:focus{
    /* color: #FFFFFF; */
  }
`
export const ButtonStyled = styled.button`
  ${Button};
`
export const ButtonSpan = styled.span`
  ${Button};
`
export const LinkButtonStyled = styled.a`
  ${Button};
`
export const LinkComponentButtonStyled = styled(Link)`
  ${Button};
`

ButtonStyled.defaultProps = {
  theme: {
    bg: color.second,
    fc: '#ffffff',
    bdc: color.second,
    bdc_hover: ''
  }
}
ButtonSpan.defaultProps = {
  theme: {
    bg: color.second,
    fc: '#ffffff',
    bdc: color.second,
    bdc_hover: ''
  }
}
LinkButtonStyled.defaultProps = {
  theme: {
    bg: color.second,
    fc: '#ffffff',
    bdc: color.second,
    bdc_hover: ''
  }
}
LinkComponentButtonStyled.defaultProps = {
  theme: {
    bg: color.second,
    fc: '#ffffff',
    bdc: color.second,
    bdc_hover: ''
  }
}