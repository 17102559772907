import { useEffect, useReducer, useCallback } from 'react';
import axios from 'axios';

import api from './api'

// functions
import { getCookie } from '~/services/functions'

const tokenCookie = getCookie('@IBFEOnline:token')
if (tokenCookie && tokenCookie !== 'undefined') { // checa se há token no cookie
  api.defaults.headers.common['Authorization'] = `bearer ${tokenCookie}`
}

const INITIAL_STATE = {
  loading: false,
  data: [],
  error: ''
}

// função pura
const reducer = (state, action) => {

  // manipular meu estado
  if (action.type === "REQUEST") {
    return {
      ...state,
      loading: true
    }
  }
  if (action.type === "SUCCESS") {
    return {
      ...state,
      loading: false,
      data: action.data,
      error: '',
    }
  }
  if (action.type === "FAILURE") {
    return {
      ...state,
      loading: false,
      data: [],
      error: action.error,
    }
  }
  return state;
}

export const useGet = resource => {
  const [data, dispatch] = useReducer(reducer, INITIAL_STATE);

  const get = useCallback(async (source) => {
    if (resource) {
      try {

        dispatch({ type: 'REQUEST' });
        const res = await api.get(resource, { cancelToken: source ? source.token : '' });

        if (res.data.erro && Object.keys(res.data.erro).length > 0) {

          dispatch({
            type: 'FAILURE',
            error: res.data.erro
          });

        } else {

          dispatch({
            type: 'SUCCESS',
            data: res.data
          });

        }

      } catch (err) {

        if (axios.isCancel(err)) {
        } else {
          throw err
        }

        dispatch({
          type: 'FAILURE',
          error: 'Ops! Algo deu errado.'
        });
      }
    }
  }, [resource])

  useEffect(() => {
    const source = axios.CancelToken.source()
    get(source)
    return () => { source.cancel() }
  }, [get]);

  const refetchCancelToken = axios.CancelToken.source()

  return { ...data, refetch: get, refetchCancelToken };
}

// export const useGet = resource => {
//   const [data, dispatch] = useReducer(reducer, INITIAL_STATE);

//   const get = useCallback(async (execute = true) => {

//     if (execute) {
//       try {

//         dispatch({ type: 'REQUEST' });
//         const res = await api.get(resource);

//         if (res.data.erro && Object.keys(res.data.erro).length > 0) {

//           dispatch({
//             type: 'FAILURE',
//             error: res.data.erro
//           });

//         } else {

//           dispatch({
//             type: 'SUCCESS',
//             data: res.data
//           });

//         }

//       } catch (err) {

//         dispatch({
//           type: 'FAILURE',
//           error: 'Ops! Algo deu errado.'
//         });

//       }
//     }

//   }, [resource])

//   useEffect(() => {
//     let execute = true;
//     get(execute);
//     return () => execute = false
//   }, [get]);

//   return { ...data, refetch: get };
// }

// export const useGet = resource => {
//   const [data, dispatch] = useReducer(reducer, INITIAL_STATE);

//   const get = async () => {

//     try {

//       dispatch({ type: 'REQUEST' });
//       const res = await api.get(resource);

//       if (res.data.erro && Object.keys(res.data.erro).length > 0) {

//         dispatch({
//           type: 'FAILURE',
//           error: res.data.erro
//         });

//       } else {

//         dispatch({
//           type: 'SUCCESS',
//           data: res.data
//         });

//       }

//     } catch (err) {

//       dispatch({
//         type: 'FAILURE',
//         error: 'Ops! Algo deu errado.'
//       });

//     }
//   }

//   useEffect(() => {
//     get()
//   }, [resource]);

//   return { ...data, refetch: get };
// }


export const usePost = resource => {

  const [data, dispatch] = useReducer(reducer, INITIAL_STATE)

  const post = async (data) => {
    dispatch({ type: 'REQUEST' });

    try {

      const res = await api.post(resource, data)

      if (res.data.erro && Object.keys(res.data.erro).length > 0) {

        dispatch({
          type: 'FAILURE',
          error: res.data.erro
        });

      } else {

        dispatch({
          type: 'SUCCESS',
          data: res.data
        });

        return res.data;

      }

    } catch (err) {

      dispatch({
        type: 'FAILURE',
        error: 'Ops! Algo deu errado.',
      });

    }
  }
  return [data, post]
}

export const useDelete = () => {

  const [data, dispatch] = useReducer(reducer, INITIAL_STATE)

  const remove = async (resource) => {
    dispatch({ type: 'REQUEST' });
    await api.delete(resource);
    dispatch({ type: 'SUCCESS' });
  }
  return [data, remove]
}

export const usePatch = (resource) => {

  const [data, dispatch] = useReducer(reducer, INITIAL_STATE)

  const patch = async (data) => {
    dispatch({ type: 'REQUEST' });

    try {

      const res = await api.patch(resource, data);

      if (res.data.erro && Object.keys(res.data.erro).length > 0) {

        dispatch({
          type: 'FAILURE',
          error: res.data.erro
        });

      } else {

        dispatch({
          type: 'SUCCESS',
          data: res.data
        });

        return res.data;

      }

    } catch (err) {

      dispatch({
        type: 'FAILURE',
        error: 'Ops! Algo deu errado.',
      });

    }

  }
  return [data, patch]
}

export const usePut = (resource) => {

  const [data, dispatch] = useReducer(reducer, INITIAL_STATE)

  const put = async (data) => {
    dispatch({ type: 'REQUEST' });

    try {

      const res = await api.put(resource, data);

      if (res.data.erro && Object.keys(res.data.erro).length > 0) {

        dispatch({
          type: 'FAILURE',
          error: res.data.erro
        });

      } else {

        dispatch({
          type: 'SUCCESS',
          data: res.data
        });

        return res.data;

      }

    } catch (err) {

      dispatch({
        type: 'FAILURE',
        error: 'Ops! Algo deu errado.',
      });

      return false

    }

  }
  return [data, put]
}